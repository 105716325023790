<template>
  <div
    class="
      bg-gray-300
      md:w-1/2
      w-screen
      md:h-1/2
      h-screen
      border-gray-400 border-2
      rounded-2xl
      p-5
    "
  >
    <div>
      <h1 class="text-2xl my-6 font-bold text-left">{{$lang.stepCarsTitle}}</h1>
      <div class="grid md:grid-cols-3 grid-cols-1 gap-4">
        <div v-for="(car, key) in $lang.stepCarsItems" :key="key"
          :class="isSelectedMark(car.name) ? '-translate-y-2 border-red-500' : ''"
          class="
            hover:-translate-y-2
            duration-300
            col-span-1
            hover:border-red-500
            border border-transparent
            shadow-2xl
            rounded-2xl
            bg-white
          "
          @click="selectCar(car.name)"
        >
          <img class="rounded-t-2xl" :src="car.image" />
          <p class="p-4 text-start items-center flex justify-between">
              {{car.name}}
              <span class="bg-gray-300 p-2 rounded-2xl">→</span>
          </p>
        </div>
      </div>
      <div class="flex justify-between md:inherit sticky bottom-5">
        <button
          class="bg-gray-500 p-5 mt-6 text-white font-bold rounded-xl md:w-96"
          @click="prevStep()"
        >
          {{$lang.stepBack}}
        </button>
        <button
          v-if="selectedMark !== ''"
          class="
            bg-red-500
            p-5
            mt-6
            text-white
            font-bold
            rounded-xl
            w-56
            md:w-96
          "
          @click="nextStep()"
        >
          {{$lang.stepForward}}
        </button>
        <button
          v-else
          class="
            bg-gray-400
            p-5
            mt-6
            font-bold
            rounded-xl
            w-56
            md:w-96
            text-gray-300
          "
        >
          {{$lang.stepForward}}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import store from '../store';

export default {
    computed: {
        selectedMark() {
            return store.state.mark;
        }
    },
    methods: {
        selectCar(mark) {
            store.commit('selectCar', mark)
        },
        isSelectedMark(mark) {
            return (store.state.mark === mark);
        },
        prevStep(){
            store.commit('prevStep');
        },
        nextStep(){
            store.commit('nextStep');
        }
    }
}
</script>