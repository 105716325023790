module.exports = {
    "mainBg": require("@/assets/img/mainBg.webp"),
    "heading": "Официальный дилер GEELY в Кургане",
    "title": "Опрос Geely | СИЛЬВЕР",
    "sloganHeading": "Мощная экономия до 1 100 000 ₽ на новый GEELY в «Сильвер-Авто»",
    "sloganItem1": " - Экономия по кредиту 400 000 рублей!",
    "sloganItem2": " - Выгодный trade-in со скидкой 200 000 рублей!",
    "sloganItem3": " - Выгода по КАСКО - 50 000 рублей",
    "sloganItem4": " - Кредит от 0,01%",
    "subSloganHeading": "Ответьте на 4 вопроса, и получите информацию по наличию и ценам",
    "buttonStart": "Начать опрос",
    "visiblePhone": "+7 352 250-91-84 ",
    "phone": "+73522509184",
    "orgInfo": "ООО «Сильвер-Авто ГРУПП ОГРН 1117456000980 ИНН 7456003755",
    "orgName": "СИЛЬВЕР",
    "orgRemark": "* Предложение носит информационный характер, не является публичной офертой (ст.437 ГК РФ)",
    "orgExpand": `ООО «Сильвер-Авто ГРУПП» оставляет за собой право на изменение сроков и условия действия Предложения в одностороннем порядке.
        <br/>Подробные условия предложения, кредитования, страхования и иные условия уточняйте у менеджеров Дилерского центра СИЛЬВЕР.Geely.
        <br/>Данный сайт носит исключительно информационно-справочный характер и ни при каких условиях не является публичной офертой.
        <br/>Количество а/м ограничено складом дилера. Все цены указанные на данном сайте носят информационный характер.
        <br/>Для получения подробной информации просьба обращаться в салон к официальному дилеру ООО «Сильвер-Авто ГРУПП».
        <br/>Опубликованная на данном сайте информация может быть изменена в любое время без предварительного`,

    "stepCarsTitle": "Выберите модель Geely",
    "stepCarsItems": [
        {
            "image": require("@/assets/img/car1.jpg"),
            "name": "Geely Tugella"
        },
        {
            "image": require("@/assets/img/car2.jpg"),
            "name": "Geely Coolray"
        },
        {
            "image": require("@/assets/img/car3.jpg"),
            "name": "Geely Atlas Pro"
        }
    ],

    "stepPaymentTitle": "Какой тип оплаты вы рассматриваете?",
    "stepPaymentItems": [
        {
            "name": "100% оплата"
        },
        {
            "name": "Свой автомобиль в зачёт"
        },
        {
            "name": "Лизинг"
        },
        {
            "name": "Кредит по ставке от 0,01%"
        }
    ],

    "stepTdriveTitle": "Хотите пройти тест-драйв?",
    "stepTdriveItems": [
        {
            "name": "Да"
        },
        {
            "name": "Не нужно"
        }
    ],

    "stepTdriveItem": "Мы уже обновляем данные по наличию и ценам",
    "stepPhoneInput": "Оставьте номер телефона",

    "stepCompletedTitle": "Спасибо за то что уделили нам своё время",

    "phoneMask": "+7 (###) ### ##-##",
    "phonePlaceholder": "+7 (___) ___ __-__",

    "mailSubject": "Опрос Geely",
    "mailTo": "leads@my-silver.ru",
    "redirectTo": "https://geely2.my-silver.ru/",

    "mailFormName": "Формочка опроса",
    "mailFormDealer": "Geely",
    "mailFormMark": "Марка автомобиля",
    "mailFormPayment": "Оплата",
    "mailFormTdrive": "Тест-Драйв",
    "mailFormPhone": "Телефон",

    "stepBack": "Назад",
    "stepForward": "Вперёд",
    "send": "Отправить",
    "siteButton": "Наш сайт",

    "calltouchFile": "/js/calltouch_kurgan_geely.js"
}