<template>
  <div
    class="
      bg-gray-300
      md:w-1/2 md:h-1/2
      w-screen
      h-screen
      border-gray-400 border-2
      rounded-2xl
      p-5
    "
  >
    <div>
      <h1 class="text-2xl font-bold">{{ $lang.stepPhoneTitle }}</h1>
      <div class="mt-6">
        <p class="text-sm">{{ $lang.stepPhoneInput }}</p>
        <input
          type="phone"
          @input="(v) => editPhone(v.target.value)"
          class="p-4 rounded-3xl font-bold"
          v-maska="$lang.phoneMask"
          :placeholder="$lang.phonePlaceholder"
        />
      </div>
      <div class="flex justify-center">
        <button
          v-if="isPhoneCorrect"
          class="bg-red-500 p-5 mt-6 text-white font-bold rounded-xl w-96"
          @click="sendForm()"
        >
          {{ $lang.send }}
        </button>
        <button
          v-else
          class="bg-gray-400 p-5 mt-6 font-bold rounded-xl w-96 text-gray-300"
        >
          {{ $lang.send }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import store from "../store";
import axios from "axios";

export default {
  computed: {
    savedPhone() {
      return store.state.phone;
    },
  },
  methods: {
    editPhone(phone) {
      store.commit("selectPhone", phone);
    },
    isPhoneCorrect() {
      return store.state.phone.length === 18;
    },
    sendForm() {

      let password = "2ksupercheburek";
      let template = `<table>
            <thead>
                <th></th>
                <th></th>
            </thead>
            <tbody>
                <tr>
                    <td>${this.$lang.mailFormName}</td>
                    <td style="padding-left: 1rem">${this.$lang.mailFormDealer}</td>
                </tr>
                <tr>
                    <td>${this.$lang.mailFormMark}</td>
                    <td style="padding-left: 1rem">${store.state.mark}</td>
                </tr>
                <tr>
                    <td>${this.$lang.mailFormPayment}</td>
                    <td style="padding-left: 1rem">${store.state.payment}</td>
                </tr>
                <tr>
                    <td>${this.$lang.mailFormTdrive}</td>
                    <td style="padding-left: 1rem">${store.state.tdrive}</td>
                </tr>
                <tr>
                    <td>${this.$lang.mailFormPhone}</td>
                    <td style="padding-left: 1rem">${store.state.phone}</td>
                </tr>
            </tbody>
        </table>`;

    if (store.state.phone.length > 0 && store.state.phone.length === 18) {
        
        this.nextStep();

        let redirectTo = this.$lang.redirectTo;

        window.ctw.createRequest('default', store.state.phone.replace(/[^0-9]/g, ''), [
          {
              "name": "Телефон",
              "value": store.state.phone.replace(/[^0-9]/g, '')
          },
          {
              "name": "Марка",
              "value": store.state.mark
          },
          {
              "name": "Оплата",
              "value": store.state.payment
          },
          {
              "name": "Тест драйв",
              "value": store.state.tdrive
          },
      ]);

        axios
          .post("https://dev.my-silver.ru/api/forms/proxy_send", {
            password: password,
            subject: this.$lang.mailSubject,
            mail: this.$lang.mailTo,
            template: template,
          })
          .then(function () {
            document.location.href = redirectTo;
          });
      }
    },
    prevStep() {
      store.commit("prevStep");
    },
    nextStep() {
      store.commit("nextStep");
    },
  },
};
</script>