<template>
  <div
    class="
      bg-gray-300
      md:w-1/2 md:h-1/2
      h-screen
      w-screen
      border-gray-400 border-2
      rounded-2xl
      p-5
    "
  >
    <div>
      <h1 class="text-2xl font-bold">{{ $lang.stepTdriveTitle }}</h1>
      <div class="grid md:grid-cols-2 gap-4 mt-6">
        <div
          v-for="(tdrive, key) in $lang.stepTdriveItems"
          :key="key"
          :class="
            isSelectedTdrive(tdrive.name) ? '-translate-y-2 border-red-500' : ''
          "
          @click="selectTdrive(tdrive.name)"
          class="
            hover:-translate-y-2
            duration-300
            col-span-1
            hover:border-red-500
            border border-transparent
            shadow-2xl
            rounded-2xl
            bg-white
            flex
            justify-between
            items-center
            p-4
          "
        >
          <p class="text-start">{{ tdrive.name }}</p>
        </div>
      </div>
      <div class="flex justify-between md:inherit sticky bottom-5 mt-4">
        <button
          class="bg-gray-500 p-5 mt-6 text-white font-bold rounded-xl md:w-96"
          @click="prevStep()"
        >
          {{ $lang.stepBack }}
        </button>
        <button
          v-if="selectedTdrive !== ''"
          class="
            bg-red-500
            p-5
            mt-6
            text-white
            font-bold
            rounded-xl
            md:w-96
            w-56
          "
          @click="nextStep()"
        >
          {{ $lang.stepForward }}
        </button>
        <button
          v-else
          class="
            bg-gray-400
            p-5
            mt-6
            font-bold
            rounded-xl
            md:w-96
            w-56
            text-gray-300
          "
        >
          {{ $lang.stepForward }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import store from "../store";

export default {
  computed: {
    selectedTdrive() {
      return store.state.tdrive;
    },
  },
  methods: {
    selectTdrive(pay) {
      store.commit("selectTestDrive", pay);
    },
    isSelectedTdrive(pay) {
      return store.state.payment === pay;
    },
    prevStep() {
      store.commit("prevStep");
    },
    nextStep() {
      store.commit("nextStep");
    },
  },
};
</script>