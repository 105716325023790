import Vue from 'vue'
import App from './App.vue'
import Maska from 'maska'
import Lang from 'vue-lang'
import $store from './store'

import VueYandexMetrika from 'vue-yandex-metrika';

import './assets/css/tailwind.css'

let locales = {
  "mgn_geely": require("../data/mgn_geely.js"),
  "kurgan_geely": require("../data/kurgan_geely.js")
}

Vue.config.productionTip = false

Vue.use(Maska);
Vue.use(Lang, {lang: process.env.VUE_APP_DEALER_CODE, locales: locales});

Vue.use(VueYandexMetrika, {
  id: process.env.VUE_APP_YANDEX_ID,
  env: 'production'
})

new Vue({
  store: $store,
  render: h => h(App)
}).$mount('#app');
