import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state () {
      return {
        step: 0,
        mark: "",
        payment: "",
        tdrive: "",
        phone: ""
      }
    },
    mutations: {
      prevStep(state){
        state.step--;
      },
      nextStep(state){
        state.step++;
      },
      selectCar(state, mark) {
        state.mark = mark;
      },
      selectPayment(state, payment) {
        state.payment = payment;
      },
      selectTestDrive(state, tdrive) {
        state.tdrive = tdrive;
      },
      selectPhone(state, phone) {
        state.phone = phone;
      }
    }
  });