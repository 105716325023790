<template>
  <div id="app" class="max-h-screen">
    <transition>
      <div class="flex justify-evenly" v-if="currentStep == 0">
        <div
          class="
            w-2/3
            h-screen
            overflow-hidden overflow-y-hidden
            bg-cover
            md:block
            hidden
          "
          :style="
            'background-position:right;background-image:url(' +
            $lang.mainBg +
            ')'
          "
        ></div>
        <div
          class="
            px-4
            md:h-auto
            h-screen
            md:w-1/2
            flex flex-col
            justify-between
            bg-white
            shadow-2xl
            border-l-4
          "
        >
          <div>
            <div class="flex md:justify-between justify-center p-6">
              <img src="./assets/logo.webp" width="100" />
              <span class="md:block hidden">{{ $lang.heading }}</span>
            </div>
            <hr />
          </div>
          <div class="text-start">
            <h1 class="text-xl font-bold mb-6 md:px-0 px-4">
              {{ $lang.sloganHeading }}
            </h1>
            <ul class="md:px-0 px-4">
              <li>{{ $lang.sloganItem1 }}</li>
              <li>{{ $lang.sloganItem2 }}</li>
              <li>{{ $lang.sloganItem3 }}</li>
              <li>{{ $lang.sloganItem4 }}</li>
            </ul>
            <p class="mt-6 text-sm md:px-0 px-4">
              {{ $lang.subSloganHeading }}
            </p>
            <button
              class="
                animate-bounce
                bg-red-500
                p-5
                mt-6
                text-white
                font-bold
                rounded-xl
                w-96
              "
              @click="nextStep()"
            >
              {{ $lang.buttonStart }}
            </button>
          </div>
          <div>
            <hr />
            <div class="flex justify-between p-6">
              <div class="text-start">
                <a class="text-red-500" :href="'tel:' + $lang.phone">{{
                  $lang.visiblePhone
                }}</a>
                <p class="text-xs">{{ $lang.orgInfo }}</p>
                <ul class="accordion">
                  <li class="accordion__item flex flex-row justify-between">
                    <p
                      @click="openAccordion"
                      class="
                        text-xs
                        accordion-trigger
                        flex flex-row
                        justify-between
                      "
                    >
                      <img
                        class="hidden md:block"
                        :class="
                          accordionActive
                            ? 'transform hover:rotate-0 rotate-180 transition duration-500 ease-in-out'
                            : 'transform hover:rotate-0 rotate-90 transition duration-500 ease-in-out'
                        "
                        src="@/assets/img/arrow.png"
                        width="15"
                      />
                      <span>{{ $lang.orgRemark }}</span>
                    </p>
                  </li>
                  <transition
                    name="accordion"
                    @enter="startAccordion"
                    @after-enter="endAccordion"
                    @before-leave="startAccordion"
                    @after-leave="endAccordion"
                  >
                    <li
                      class="text-xs accordion__content"
                      v-show="accordionActive"
                      v-html="$lang.orgExpand"
                    ></li>
                  </transition>
                </ul>
              </div>
              <p class="text-xs">{{ $lang.orgName }}</p>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div
      class="flex justify-center mx-auto md:mt-12 w-full"
      v-if="currentStep == 1"
    >
      <step-cars />
    </div>
    <div class="flex justify-center md:mt-12 mx-auto" v-if="currentStep == 2">
      <step-payment />
    </div>
    <div class="flex justify-center md:mt-12 mx-auto" v-if="currentStep == 3">
      <step-tdrive />
    </div>
    <div class="flex justify-center md:mt-12 mx-auto" v-if="currentStep == 4">
      <step-phone />
    </div>
    <div class="flex justify-center md:mt-12 mx-auto" v-if="currentStep == 5">
      <step-completed />
    </div>
  </div>
</template>

<script>
import store from "./store";

import StepCars from "./components/StepCars.vue";
import StepPayment from "./components/StepPayment.vue";
import StepTdrive from "./components/StepTdrive.vue";
import StepPhone from "./components/StepPhone.vue";
import StepCompleted from "./components/StepCompleted.vue";

import {loadScript} from "vue-plugin-load-script";

export default {
  name: "App",
  data() {
    return {
      accordionActive: false,
    };
  },
  components: {
    "step-cars": StepCars,
    "step-payment": StepPayment,
    "step-tdrive": StepTdrive,
    "step-phone": StepPhone,
    "step-completed": StepCompleted,
  },
  mounted() {

    document.title = this.$lang.title;

    loadScript(this.$lang.calltouchFile).then(() => {
      console.log(window);
    });
  },
  computed: {
    currentStep() {
      return store.state.step;
    },
  },
  methods: {
    nextStep() {
      store.commit("nextStep");
    },
    openAccordion() {
      this.accordionActive = !this.accordionActive;
    },
    startAccordion(el) {
      el.style.height = el.scrollHeight + "px";
    },
    endAccordion(el) {
      el.style.height = "";
    },
  },
};
</script>
